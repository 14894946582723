<template>
  <div class="container">
    <div class="header">
      <div>
        <div><span>集成线路</span> > <span>一期1#逆变器</span></div>
        <div class="header-flex">
          <div>型号：SG225HX</div>
          <div>容量（kW）：225.00</div>
          <div>等效利用小时数（h）：0.28</div>
          <div>转换效率（%）：95.17</div>
        </div>
      </div>
      <div>
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>
    </div>
    <!-- 4盒子 -->
    <div>
      <el-row :gutter="15">
        <!--  左1 -->
        <el-col :span="12">
          <div class="item">
            <div class="item-head-class">
              <div class="title-class">发电量统计</div>
              <img
                src="../../../assets/images/yunwei/home-background.svg"
                class="background-img"
                alt=""
              />
            </div>
            <!-- <img
              class="img"
              src="../../../assets/images/Monitor/item-title1.png"
              alt=""
            /> -->
            <el-row :gutter="20" style="margin-top: 15px">
              <el-col
                :span="12"
                v-for="(item, index) in left1"
                :key="index"
                class="item-col"
                style="justify-content: space-between"
              >
                <span class="span1">{{ item.key }}</span>
                <span class="span2">{{ item.value }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <!-- 右1 -->
        <el-col :span="12">
          <div class="item">
    <div class="item-head-class">
              <div class="title-class">测试光字牌</div>
              <img
                src="../../../assets/images/yunwei/home-background.svg"
                class="background-img"
                alt=""
              />
            </div>
            <el-row :gutter="20" style="margin-top: 15px">
              <el-col
                :span="8"
                v-for="(item, index) in right1"
                :key="index"
                class="item-col"
              >
                <span class="success"></span>
                <span class="span2" style="color: #fff">{{ item }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <!-- 左2 -->
        <el-col :span="12">
          <div class="item">
           <div class="item-head-class">
              <div class="title-class">功率辐照</div>
              <img
                src="../../../assets/images/yunwei/home-background.svg"
                class="background-img"
                alt=""
              />
            </div>
            <left /></div
        ></el-col>
        <el-col :span="12"
          ><div class="item">
               <div class="item-head-class">
              <div class="title-class">支路电流</div>
              <img
                src="../../../assets/images/yunwei/home-background.svg"
                class="background-img"
                alt=""
              />
            </div>
            <right /></div
        ></el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="item">
      <div>
        <div class="item-head-class">
              <div class="title-class">逆变器状态</div>
              <img
                src="../../../assets/images/yunwei/home-background.svg"
                class="background-img"
                alt=""
              />
            </div>
      </div>
      <div class="table-box">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="时间">
            <el-input v-model="form.user"></el-input>
          </el-form-item>
          <el-form-item label="事件类型">
            <el-select v-model="form.region">
              <el-option label="设备故障" value="1"></el-option>
              <el-option label="设备正常" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="告警信息">
            <el-input v-model="form.user"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary">查询</el-button>
          </el-form-item>
        </el-form>
        <div class="my-table">
          <el-table :data="tableData">
            <el-table-column
              prop="name"
              label="事件类型"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="date"
              label="发生时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="address"
              label="告警信息"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="address"
              label="动作"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import right from "../components/device/right.vue";
import left from "../components/device/left.vue";
export default {
  components: { left, right },
  data() {
    return {
      left1: [
        { key: "总发电量（KWh）", value: "121336.40" },
        { key: "总发电量（KWh）", value: "66.80" },
        { key: "交流功率（kW）", value: "11.66" },
        { key: "AB线电压（V）", value: "764.80" },
        { key: "BC线电压（V）", value: "765.80" },
        { key: "CA线电压（V）", value: "765.30" },
        { key: "A向电流（A）", value: "9.50" },
        { key: "B相电流（A）", value: "9.80" },
        { key: "功率因数", value: "1.00" },
        { key: "逆变器温度（℃））", value: "30.90" },
        { key: "无功功率（kVar）", value: "1.00" },
        { key: "支路电压1（V）", value: "1080.40" },
        { key: "支路电压2（V）", value: "1089.40" },
        { key: "支路电压3（V）", value: "1216.40" },
      ],
      right1: [
        "总故障位",
        "总运行位",
        "总停机位",
        "运行",
        "停机",
        "按键关机",
        "紧急停机",
        "待机",
        "初始待机",
        "启动中",
        "告警运行",
        "限额运行",
        "调度运行",
        "故障停机",
        "通讯故障",
        "电网过压",
        "电网欠压",
        "电网过频",
        "电网欠顿",
        "孤岛",
        "漏电流超标",
      ],
      form: {},
      tableData: [],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button {
  width: 80px !important;
}
.my-table {
  border-bottom: 1px solid #1c4b76;
}
.my-table ::v-deep .el-table td.el-table__cell,
.my-table ::v-deep .el-table th.el-table__cell.is-leaf {
  background-color: #21527e !important;
  border: 1px solid #1c4b76;
  color: #fff !important;
}

::v-deep .el-table__empty-block {
  background-color: #2d5981;
  border: 1px solid #5f809d;
}
::v-deep .el-table__body-wrapper {
  border-bottom: none !important;
}
::v-deep .el-table::before {
  z-index: 0 !important;
}

.container {
  background-color: #223f6c;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
  .header {
    height: 50px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    .header-flex {
      display: flex;
      width: 700px;
      justify-content: space-between;
      margin-top: 15px;
    }
  }

  .item {
    background-color: #2d5981;
    height: 400px;
    margin: 15px 0 0 0;
    padding: 20px;
    box-sizing: border-box;
    .img {
      width: 100%;
    }
    .item-col {
      height: 40px;
      display: flex;

      align-items: center;
      border-bottom: 1px solid #446b90;
      .span1 {
        color: #fff;
        font-size: 16px;
      }
      .span2 {
        color: #06a3ef;
        font-size: 16px;
      }
      .success {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #7cc537;
        border-radius: 50%;
        margin-right: 15px;
      }
    }
  }

  .table-box {
    margin-top: 20px;
  }
}
::v-deep .el-form-item--small .el-form-item__label {
  color: #fff !important;
}
::v-deep .el-input--small .el-input__inner {
  background-color: #2d5981 !important;
  color: #fff !important;
  border: 1px solid #5f809d;
  width: 150px;
}

.item-head-class {
  display: flex;
  height: 38px;
  width: 100%;
  border-bottom: 1px solid #009fde;
  position: relative;
  .title-class {
    width: 100px;
    height: 100%;
    line-height: 40px;
    padding-left: 5px;
    font-family: "Arial", sans-serif;
    color: #ffffff;
    font-size: 15px;
    background: #00b2e6;
  }
  .background-img {
    height: 100%;
    position: absolute;
    left: 67px;
  }
}
</style>
