import { render, staticRenderFns } from "./left.vue?vue&type=template&id=d10c1bb8"
import script from "./left.vue?vue&type=script&lang=js"
export * from "./left.vue?vue&type=script&lang=js"
import style0 from "./left.vue?vue&type=style&index=0&id=d10c1bb8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "left.vue"
export default component.exports