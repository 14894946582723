<template>
  <div>
    <div id="box" class="charts"></div>
  </div>
</template>

<script>
import { left } from "./ehcarts";
import * as echarts from "echarts";
export default {
  methods: {
    init() {
      setTimeout(() => {
        this.box = echarts.init(document.getElementById("box"));
        let option = left;
        this.box.setOption(option);
      }, 200);
      window.onresize = function () {
        if (this.box) {
          this.box.resize(); // 调用 ECharts 实例的 resize 方法
        }
      }.bind(this);
    },
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    this.box.dispose();
  },
};
</script>

<style>
.charts {
  width: 100%;
  height: 330px;
}

</style>
